.carousel-t1{
  .swiper-wrapper{
    .swiper-slide{
      padding: 10px;
    }
  }

  .swiper-button-prev, .swiper-button-next{
    margin-top: -38px;
    width: 40px;
    height: 76px;
    background-size: 30px 70px;
    opacity: 0.85;
    @include  transition(200ms);

    &:after{
      font-size: 35px;
    }

    &:hover{
      opacity: 1;
    }
  }

  .swiper-button-prev{
    left: 15px;
    color: $cprimary;
  }
  .swiper-button-next{
    right: 15px;
    color: $cprimary;
  }

  &.ct1-white{
    .swiper-button-prev{
      color: #ffffff;
    }
    .swiper-button-next{
      color: #ffffff;
    }
  }

  @media screen and (max-width: 575px){
    .swiper-button-prev, .swiper-button-next{
      background-size: 25px 58px;
    }
    .swiper-button-prev{
      left: 12px;
    }
    .swiper-button-next{
      right: 12px;
    }
  }
}
