.account-page{
  min-height: 70vh;
  font-family: "Raleway", sans-serif;
  background-color: #ebecf0;
  // background-image: url('../images/pages/user/bg-user.jpg');

  .main-box{
    margin-top: 40px;
    margin-bottom: 40px;
    // background-color: #fff;

    .btn-red{
      background-color: $cprimary;
      border-color: $cprimary;

      &:hover, &:active{
        background-color: $cprimary2;
        border-color: $cprimary2;
      }
    }

    .page-title{
      color: #b6b7b7;
      font-weight: 100;
    }

    .white-box{
      display: inline-block;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 0 3px rgba(0,0,0,0.1);
    }

    .c-hr{
      margin-top: 0.25rem;
      margin-bottom: 0.75rem;
    }
    .c-hr-bold{
      border-top: 2px solid rgba(61, 61, 62, 0.9);
    }

    .btn-form{
      min-width: 160px;
      max-width: 100%;
      font-weight: 300;
    }

    a{
      cursor: pointer;
    }

    .col-menu{
      @media screen and (min-width: 992px){
        flex: 0 0 29%;
        max-width: 29%;
      }
      @media screen and (max-width: 991px){
        margin-bottom: 20px;

        .white-box{
          background-color: #ededed;

          .user-icon{
            .fa-user{
              color: #ededed;
            }
          }
        }
      }

      .box-profile{
        display: block;
        min-height: 163px;
        text-align: center;

        .user-icon{
          color: #c7cbcc;
        }
        .txt-username{
          margin-top: 0.5rem;
          color: #b9bfc0;
          font-size: 1.45rem;
          font-weight: 400
        }
      }

      .box-menu{
        padding-bottom: 20px;

        .item{
          &:last-child{
            .btn-menu{
              border-bottom: none;
            }
          }

          .btn-menu{
            display: block;
            padding: 5px 25px;
            color: #505759;
            font-size: 0.95rem;
            font-weight: 400;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            cursor: pointer;
            @extend .t-250;

            &:hover{
              color: #fff;
              text-decoration: none;
              background-color: $cprimary;
            }
          }

          .btn-menu.router-link-exact-active{
            color: #fff;
            background-color: $cprimary;
          }
        }
      }

      .box-menu-movil{
        @media screen and (max-width: 991px){
          display: none !important;
        }
      }

      .box-movil{
        margin-top: -19px;
        padding-bottom: 4px;
        background: #dedfdf;
        padding-top: 4px;
        @media screen and (min-width: 992px){
          display: none;
        }

        .item{
          .btn-menu{
            &:hover{
              color: #505759 !important;
              background-color: transparent !important;
            }
          }
        }
      }
    }



    // Sección del carrito
    .col-cart-data{
      .product-list-table{
        min-height: 10vh;

        .row-history{
          .col-image{
            padding-bottom: 10px;
          }
          .col-info{
            padding: 5px 15px 20px 15px;
            text-align: left;

            .txt-name{
              width: 290px;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 1rem;
              overflow: hidden;
            }

            .txt-extra-info{
              padding-top: 4px;
              font-size: 0.8rem;

              strong{
                font-weight: 500;
              }
            }
          }
          .col-price{
            flex: 0 0 135px;
            padding-left: 10px;
            padding-right: 10px;
            max-width: 135px;
            font-size: 0.90rem;

            .col-title{
              color: $cprimary;
              font-weight: 400;
              font-size: 0.9rem;
            }

            p{
              font-weight: 500;
            }
          }
          .col-num{
            flex: 0 0 70px;
            max-width: 70px;

            .col-title{
              color: $cprimary;
              font-weight: 400;
              font-size: 0.8rem;
            }
          }
          .col-num-input{
            flex: 0 0 95px;
            max-width: 95px;

            .col-title{
              color: $cprimary;
              font-weight: 400;
              font-size: 0.8rem;
            }
          }
        }
      }

      .user-data{
        ._p{
          display: block;
          margin-bottom: 1rem;
        }

        .txt-simple{
          line-height: 1;
        }

        .payment-options-container{

          .col-box{
            flex: 0 0 230px;
            max-width: 230px;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-bottom: 10px;

            .box{
              padding: 15px 10px 12px 10px;
              color: #434647;
              text-align: center;
              border: 3px solid #798284;
              border-radius: 10px;
              cursor: pointer;
              @extend .t-150;

              h6{
                margin-bottom: 4px;
                height: 38px;
                line-height: 1.1;
              }
              .icons{
                min-height: 37px;
                line-height: 1;
                font-size: 32px;
              }

              &:hover{
                color: #5a5d5f;
                border-color: #99a4a7;
              }
            }

            .active{
              color: $cprimary !important;
              border-color: $cprimary !important;
            }
          }
        }
      }

      .row-total{
        color: #595656;
        font-size: 1.1rem;
        font-weight: 400;

        .btn-outline-danger{
          color: $cprimary2;
          border-color: $cprimary2;

          &:hover{
            color: #fff;
            background-color: $cprimary2;
          }
        }
        .btn-danger{
          border-color: $cprimary2;
          background-color: $cprimary2;
        }

        h5,h6{
          margin-bottom: 1px;
          line-height: 1.4;

          span{
            font-weight: 300;
          }
        }

        h6{
          font-size: 1.0rem;
        }
        h5{
          color: $cprimary2;
          font-size: 1.1rem;
          font-weight: 400;
        }

        .btn-action{
          min-width: 160px;
        }
      }
    }
    .loggedin-cart{
      margin-top: -1.5rem;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      width: calc(100% + 1.5rem + 1.5rem);
    }
    // ===
  }

  .u-uploaded-images-section{
    padding: 0;
    margin-top: 45px;

    .col-image{
      padding: 15px;

      .box{
        position: relative;
        border: 1px solid #dee2e6 !important;
        border-radius: 0.25rem;
        @extend .placed-backg;

        button{
          position: absolute;
          padding: 2px 9px;
          top: 5px;
          right: 4px;
          color: #fff;
          font-size: 0.8rem;
          border: #d50808;
          border-radius: 3px;
          background-color: #e30808;
          cursor: pointer;
          z-index: 9;
          @extend .t-250;

          &:hover{
            background-color: #d50808;
          }
        }
      }
    }
  }

  .custom-file{
    .custom-file-label{
      &:after{
        content: "Examinar";
      }
    }
  }
}

// == Row/tabla de productos usando divs ==
.row-products{
  border-bottom: 1px solid rgba(0,0,0,0.1);
  &:last-child(){
    border-bottom: 0;
  }

  a{
    cursor: pointer;
  }

  .col-a-id{
    flex: 0 0 130px;
    font-size: 0.9rem;

    strong{
      font-weight: 500;
    }
  }
  .col-a-descr{
    padding: 9px 15px;
    color: $cprimary;
    font-size: 0.75rem;
    line-height: 1.2;

    .col-title{
      font-size: 0.9rem;
    }
    .txt-descr{
      max-height: 29px;
      overflow: hidden;
    }
  }
  .col-a-downlods{
    padding: 10px 15px;
    flex: 0 0 185px;
    text-align: center;

    .btn-outline-red{
      width: auto;

      &.btn-sm{
        padding: 0.25rem 0.8rem !important;
        font-size: 0.7rem;
      }
    }
  }

  .col-image{
    padding: 20px 10px;
    flex: 0 0 130px;
    max-width: 130px;
    text-align: center;

    img{
      display: inline-block;
      max-width: 95%;
      max-height: 70px;
    }
  }

  .col-info{
    padding: 15px 7px;

    .txt-name{
      font-size: 1.05rem;
      a{
        color: #212529;
      }
    }
    .txt-md{
      font-size: 0.95rem;
      font-weight: 400;
    }

    .btn-remove{
      font-size: 0.85rem;
      color: $cprimary;
      cursor: pointer;

      i{
        font-size: 0.7rem;
      }

      &:hover{
        color: $cprimary2;
        text-decoration: none;
      }
    }
  }
  .col-info.col-a-downlods{
    // flex: 0 0 auto;
    flex-grow: 1 !important;
    flex-basis: 0 !important;
  }

  .col-status{
    flex: 0 0 115px;
    max-width: 115px;
    padding-top: 15px;
    padding-bottom: 15px;

    h6{
      font-size: 0.80rem;

      .label{
        display: block;
      }
      .status{
        text-transform: uppercase;
      }
    }
  }
  .col-price{
    flex: 0 0 250px;
    max-width: 250px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: $cprimary;
    font-size: 1.03rem;
    font-weight: 400;
  }
  .col-num{
    padding-top: 15px;
    padding-bottom: 15px;
    flex: 0 0 95px;
    max-width: 95px;

    .form-control{
      line-height: 1.3;
      border-color: #c1c2c3;
      border-radius: 0.15rem;
      background-color: transparent;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px){
    .col-image{
      flex: 0 0 120px;
      max-width: 120px;
    }
    .col-price{
      flex: 0 0 170px;
      font-size: 0.95rem;
    }
    .col-num{
      .form-control{
        line-height: 1.2;
        font-size: 0.95rem;
      }
    }
  }
  @media screen and (max-width: 767px){
    -ms-flex-align: center !important;
    align-items: center !important;

    .col-info{
      flex: 0 0 100%;
      max-width: 100%;
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
      padding: 0px 25px 15px 25px;

      .txt-name{
        width: 100% !important;
        font-size: 0.98rem;
      }
      .txt-md{
        font-size: 0.9rem;
      }
    }
  }
}
// == end row/table ==

// == Row totales ==
.row-totals{
  padding-top: 15px;
  font-size: 0.95rem;

  .col-content{
    flex: 0 0 280px;
    max-width: 280px;
  }

  .col-label{
    flex:0 0 120px;
    max-width: 120px;
    text-align: right;
  }
}
// == ==
