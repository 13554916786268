#credit-page{
  position: relative;
  color: #2b2a2a;
  background-color: #ffffff;
  overflow: hidden;

  .content-section{
    padding-top: 35px;
    padding-bottom: 35px;

    >.row{
      min-height: 60vh;
    }

    .col-info{
      color: #464646;
      font-size: 0.95rem;
      line-height: 1.4;
      text-align: center;

      a {
        color: $csecondary;
      }

      #spanone, #spantwo {
        color: $csecondary;
      }

      .page-title{
        color: $csecondary;
        font-size: 1.80rem;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
      }

      .subtitle-sm{
        color: $csecondary;
        font-size: 1.05rem;
        font-weight: 600;
      }

      a {
        color: $csecondary;
        text-decoration: underline;
      }

      h5 {
        font-size: 1.20rem;
      }

      h4,h5,h6 {
        font-weight: 600;
      }

      ul{
        padding-left: 15px;
      }
      ol{
        padding-left: 15px;
      }

      .box-qa {
        margin-bottom: 10px;

        .qa {
          display: block;
          padding-bottom: 10px;

          .q {
            margin-bottom: 3px;
            font-size: 1.15rem;
            font-weight: 600;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px){
    .bg-image{
      width: 100%;
      opacity: 0.17;
    }
  }
  @media screen and (max-width: 575px){
    .bg-image{
      width: 111%;
      background-position: top center;
    }

    .content-section{
      .col-info{
        .page-title{
          font-size: 1.80rem;
        }
      }
    }
  }

}

.faqs-page{

}
