// == Article container ==
.col-article-sample-1{
  padding: 0;
  @include transition(150ms);

  &:hover{
    background-color: $cprimary2;

    .box-article{
      color: #ffffff !important;
      text-decoration: none !important;
    }

    .box-descr{
      h5{
        color: #ffffff;
      }
    }
  }

  .box-article{
    position: relative;
    display: inline-block;
    padding: 20px;
    width: 100%;
    color: #4d4e4f;
    @include transition(150ms);
  }

  .box-image{
    display: inline-block;
    width: 100%;
    background-color: rgba(0,0,0,0.1);

    img{
      width: 100%;
    }
  }

  .box-descr{
    position: relative;
    display: inline-block;
    padding: 12px 0 0 0;
    width: 100%;

    h5{
      position: relative;
      height: 41px;
      color: $cprimary;
      font-size: 1.05rem;
      font-weight: 600;
      overflow: hidden;
      @include transition(150ms);
    }

    .descr{
      position: relative;
      display: block;
      font-size: 0.90rem;
      line-height: 1.3;
      text-align: justify;
      max-height: 57px;
      overflow: hidden;
    }
  }

  &.sample-2{
    .box-article{
      padding: 15px 15px;
    }

    .box-image{
      display: block;
      height: 220px;
      overflow: hidden;
    }

    .box-descr{
      display: block;

      h5{
        margin-bottom: 4px;
        height: auto;
      }
      .descr{
        max-height: 38px;
      }
    }
  }
}
// == ==

// == Blog index ==
#blog-page{
  padding-bottom: 25px;

  .articles-section{
    .col-page-title-t2{
      margin-bottom: 12px;
    }

    .col-form{
      form{
        position: relative;
        width: 100%;

        .form-control{
          border-color: $cprimary;
        }

        .btn-search{
          position: absolute;
          top: 0;
          right: 0;
          width: 100px;
          background-color: $cprimary;
          border-color: $cprimary;

          &:hover{
            background-color: $cprimary2;
            border-color: $cprimary2;
          }
        }
      }
    }

    .col-pagination-sample-1{
      margin-top: 15px;
    }
  }
}
// == ==

// == Article page (blog detail) ==
#article-page{

  .content-section{
    padding-bottom: 20px;

    .col-page-title-t2{
      margin-bottom: 12px;
    }

    .col-article{
      .box-image{
        display: inline-block;
        margin-bottom: 3px;
        width: 100%;

        img{
          width: 100%;
        }
      }

      h1{
        color: $cprimary;
        font-size: 1.5rem;
        font-weight: 500;
      }

      .descr{
        display: inline-block;
        margin-top: 6px;
        width: 100%;
        color: #5d5d5d;
        font-size: 0.95rem;
        line-height: 1.3;

        a{
          color: $cprimary
        }

        ul{
          padding-left: 19px;
        }

        img{
          max-width: 100% !important;
        }
      }
    }

    .col-blog{
      .col-article-sample-1{
        &.sample-2{
          &:after{
            position: absolute;
            content: " ";
            bottom: -1px;
            left: 15px;
            width: calc(100% - 30px);
            border-bottom: 1px solid $cprimary2;
            @include transition(100ms);
          }
        }

        &:hover{
          &.sample-2{
            &:after{
              opacity: 0;
            }
          }
        }

        &:last-child{
          &.sample-2{
            &:after{
              display: none;
            }
          }
        }
      }

      @media screen and (max-width: 991px){
        display: none;
      }
    }
  }

}
// == ==
