#description-page{
  padding-bottom: 20px;

  .description-section{

    .col-gallery{
      .swiper-container{
        .swiper-wrapper{
          .swiper-slide{
            .image-container{
              @extend .flex-center-xy;
              flex: 0 0 100%;
              max-width: 100%;
              max-height: 530px;

              .img{
                position: relative;
                display: inline-block;
                text-align: center;
                max-width: 100%;

                &:after {
                  content: " ";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-image: url('../images/watermark.png');
                  background-size: 75%;
                  opacity: 0.8;
                  z-index: 10;
                }

                img{
                  display: inline-block;
                  max-width: 100%;
                  max-height: 500px;
                }
              }
            }
          }
        }

        .swiper-pagination{
          bottom: 15px;

          .swiper-pagination-bullet{
            width: 11px;
            height: 11px;
            background-color: $cprimary;
            opacity: 0.4;
          }
          .swiper-pagination-bullet-active{
            background-color: $cprimary;
            opacity: 1 !important;
          }
        }
      }
    }

    .col-description{
      .bar-fav{
        position: relative;
        display: inline-block;
        width: 100%;
        height: 1px;

        .btn-fav{
          position: absolute;
          padding: 0;
          bottom: -20px;
          right: 0;
          width: 40px;
          height: 40px;
          border: 0;
          background-color: transparent;

          .icon{
            display: inline-block;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url('../images/shared/favorite-icon.svg');
          }

          &.actived, &:hover{
            .icon{
              background-image: url('../images/shared/favorite-filled-icon.svg');
            }
          }
        }

        @media screen and (max-width: 575px){
          .btn-fav{
            padding: 11px;
            margin-right: 8px;
            bottom: 14px;
            width: 50px;
            height: 50px;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4) !important;

            .icon{
              margin-top: 2px;
            }
          }
        }
      }

      >.brand{
        margin-bottom: 5px;
        color: #959595;
        font-weight: 400;
      }

      >.name{
        color: $cprimary;
        font-size: 1.7rem;
        font-weight: 700;
      }
      .extra-breadcrumb{
        color: #828282;
        font-size: 0.90rem;
        line-height: 1.25;

        a{
          color: #828282;
        }
      }
      .buble{
        padding: 4px 6px;
        color: #074bb5 !important;
        font-size: 0.85rem;
        text-decoration: none !important;
        border-radius: 4px;
        background-color: rgba(13, 92, 217, 0.2);
        @include transition(150ms);

        &:hover{
          background-color: rgba(13, 92, 217, 0.3);
        }
      }
      >.price{
        color: #1b1a1d;
        font-size: 2.2rem;
      }
      >.discount{
        color: #a6a3a3;
        text-decoration: line-through;

        span{
          font-size: 88%;
        }
      }

      .custom-sizes-radio-group{
        .custom-control{
          padding: 0;
          margin-top: 3px;
          margin-bottom: 3px;
          margin-right: 6px;

          .custom-control-label{
            span{
              display: block;
              padding: 5px 4px 3px 4px;
              min-width: 60px;
              color: #7d7d7d;
              font-size: 0.85rem;
              font-weight: 500;
              text-align: center;
              cursor: pointer;
              background-color: #fbfbfb;
              @include transition(150ms);
            }

            &:before, &:after{
              display: none !important;
            }

            &:hover{
              span{
                background-color: #fbe7e0;
              }
            }
          }

          .custom-control-input:checked ~ .custom-control-label{
            span{
              color: #ffffff;
              background-color: $cprimary !important;
            }
          }
        }
      }

      .box-colors{
        .wrapper{
          position: relative;
          display: flex;
          flex: 0 0 100%;
          width: 100%;
          margin-left: -6px;
        }

        .box-color-opt{
          display: inline-block;
          padding: 2px 2px;
          margin: 6px 4px;
          width: 37px;
          height: 37px;
          line-height: 1;
          border-radius: 0;
          border: 2px solid transparent;
          cursor: pointer;
          @include transition(100ms);

          .color{
            position: relative;
            display: inline-block;
            width: 29px;
            height: 29px;
            line-height: inherit;
            border-radius: 0;
            border: 1px solid rgba(0,0,0,0.07);
            @extend .placed-backg;
          }
          .img-outli{
            border-color: #dbdbdb;
          }

          &:hover{
            border-color: rgba(0,0,0,0.1);
          }

          &.active{
            border-color: #510027 !important;
          }

          &.opt-sm{
            padding: 2px !important;
            width: 25px !important;
            height: 25px !important;

            .color{
              width: 17px !important;
              height: 17px !important;
            }
          }
        }

        .input-color-opt{
          padding-left: 0;
          margin-left: 0;
          margin-right: 0;

          .custom-control-label{
            &:before, &:after{
              visibility: visible;
              opacity: 0;
            }
          }

          .custom-control-input:checked ~ .custom-control-label .box-color-opt{
            border-color: #510027 !important
          }
        }
      }

      .subtitle{
        color: #a29e9e;
        font-size: 1rem;
        font-weight: 600;
      }

      .form-control{
        border-color: #535353;
      }

      .txt-description{
        margin-bottom: 10px;
        color: #828282;
        font-size: 0.9rem;
        line-height: 1.4;

        p{
          margin-bottom: 4px;

          &:last-child{
            margin-bottom: 0;
          }
        }
      }

      .box-options{
        position: relative;
        padding-top: 20px;
        padding-bottom: 15px;
        width: 100%;

        .col-opts{
          margin: 4px 5px;

          &:first-child{
            margin-left: 0;
          }
          &:last-child{
            margin-right: 0;
          }
        }

        .col-sizes{
          max-width: 115px;
        }
        .col-quantity{
          max-width: 135px;
        }
        .col-extra-selects{
          max-width: 210px;
        }
        .col-short{
          max-width: 90px;
        }
        .col-selects{
          .custom-select{
            border-color: #000000;
            font-size: 0.92rem;
          }
        }
      }

      .btn-submit{
        width: 210px;
        max-width: 100%;
        padding: 0.55rem 0.75rem;
        color: #fff;
        font-size: 0.85rem;
        font-weight: 600;
        border-color: $cprimary;
        border-radius: 0;
        background-color: $cprimary;

        &:hover{
          border-color: $cprimary2;
          background-color: $cprimary2;
        }

        &.btn-contact{
          background-color: #353434;
          border-color: #353434;

          &:hover{
            background-color: #444343;
            border-color: #444343;
          }
        }
      }

      .col-extra{
        position: relative;
        display: inline-block;

        h6{
          margin-bottom: 5px;
          font-size: 0.90rem;
        }

        .btn-q{
          position: relative;
          display: inline-block;
          margin: 1px 4px;
          color: #ffffff;
          border-radius: 2px;
          background: $cprimary;
          cursor: pointer;
          @include transition(250ms);

          span{
            display: flex;
            flex-wrap: wrap;
            width: 34px;
            flex: 0 0 34px;
            max-width: 34px;
            height: 34px;
            display: flex;
            -ms-flex-pack: center !important;
            justify-content: center !important;
            -ms-flex-align: center !important;
            align-items: center !important;
          }

          &:hover{
            background-color: $cprimary;
          }
        }

        .b-print{
          padding-left: 0;
          flex: 0 0 95px;
          max-width: 95px;
        }
      }

      @media screen and (max-width: 575px){
        .box-seller-information{
          margin-left: -15px;
          margin-right: -15px;
          margin-top: 15px;
          padding: 20px 17px 20px 17px;
          width: auto;
          border-top: 2px solid rgba(0,0,0,0.12);

          .col-info{
            max-width: calc(100% - 50px);
          }
          .col-links{
            display: none;
          }
        }

        >.name{
          font-size: 1.6rem;
        }
        >.price{
          font-size: 1.3rem;
        }
        .txt-description{
          font-size: 0.85rem;
          text-align: justify;
        }
        .btn-submit{
          padding: 0.7rem 0.75rem;
          width: 100%;
        }
      }
    }

    .col-all-comments{
      .wr-comments{
        .col-total-comments{
          text-align: center;

          .box{
            display: inline-block;
            padding: 2px 13px 5px 13px;
            color: #ffffff;
            font-size: 1.5rem;
            font-weight: 700;
            border-radius: 4px;
            background-color: $cprimary;

            small{
              font-size: 56%;
              font-weight: 500;
            }
          }
        }

        .col-comments{
          position: relative;
          max-height: 230px;
          overflow: auto;

          .col-comment{
            margin-top: 6px;
            margin-bottom: 6px;

            .box{
              position: relative;
              display: inline-block;
              width: 100%;
              // padding: 10px 15px 12px 15px;
              // border: 1px solid rgba(0,0,0,0.3);
              // border-radius: 2px;

              .box-stars{
                .b-rating{
                  padding-left: 0;
                  padding-right: 0;
                  border: 0;
                  height: auto;
                  pointer-events: none;

                  .b-rating-star{
                    font-size: 1.1rem;

                    &:first-child{
                      padding-left: 0;
                    }
                  }
                }
              }

              .box-comment{
                display: inline-block;
                margin-top: 0;
                color: #4a4949;
                font-size: 0.9rem;
                line-height: 1.3;
                text-align: justify;
              }
            }
          }
        }
      }
    }

    .col-comment-form{
      .col-comments{
        position: relative;
        padding: 0;
        width: 100%;

        .box-comment{
          position: relative;
          width: 100%;

          h5{
            margin-bottom: 10px;
            font-size: 1.15rem;
            font-weight: 700;
          }

          .box-stars{
            position: relative;
            display: inline-block;
            width: 100%;

            .b-rating{
              height: auto;
              padding: 0;
              // width: 230px;
              border: 0;

              .b-rating-star{
                font-size: 1.45rem;

                &:first-child{
                  padding-left: 0;
                }
              }
            }
          }

          .box-input{
            position: relative;
            display: inline-block;
            margin-top: 5px;
            width: 96%;
            @media screen and (max-width: 767px){
              width: 100%;
            }

            textarea{
              resize: none;
            }

            .btn-co{
              width: 180px;
              font-size: 1.05rem;
              font-weight: 500;
              border-color: $cprimary;
              background-color: $cprimary;

              &:hover{
                background-color: $cprimary2;
              }
            }
          }
        }
      }
    }

    .col-extrainfo{
      margin-top: 20px;
      border-bottom: 2px solid rgba(0,0,0,.18);

      .row-info{
        border-top: 2px solid rgba(0,0,0,.18);

        .col-label, .col-txt{
          padding-top: 14px;
          padding-bottom: 14px;
        }

        .col-label{
          h5{
            color: #222121;
            font-size: 0.95rem;
          }
        }

        .col-txt{
          color: #828282;
          font-size: 0.95rem;
          line-height: 1.3;

          p{
            margin-bottom: 4px;

            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }

      @media screen and (max-width: 991px){
        .row-info{
          .col-label{
            padding-bottom: 0;
          }
          .col-txt{
            padding-top: 4px;
          }
        }
      }
    }
  }

  .related-products-section{
    margin-top: 50px;
  }

}
