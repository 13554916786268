#home-page{

	// == Banners ==
	.banner-section{
		padding: 0;

		.swiper-container{
			// box-shadow: 0 3px 5px rgba(0,0,0,0.25);

			.swiper-wrapper{
				.swiper-slide{
					a{
						display: block;
					}

					img{
						width: 100%;
						// display: block;
					}
				}
			}

			.swiper-pagination{
				padding: 5px;

				.swiper-pagination-bullet{
					position: relative;
					width: 14px;
					height: 14px;
					background: #ffffff;
					opacity: 1;
					box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 6px;
				}
				.swiper-pagination-bullet-active{
				}
			}

			.swiper-button-prev, .swiper-button-next{
				color: #ffffff;
			}
		}

		.swiper-desktop{}
		.swiper-mobile{
			display: none;
		}

		@media screen and (max-width: 991px){
			.swiper-desktop{
				display: none;
			}
			.swiper-mobile{
				display: block;
			}

			.swiper-container{
				.swiper-button-prev, .swiper-button-next{
					&:after{
						font-size: 28px;
					}
				}
			}
		}
	}
	// == ==

	// == Multi banners ==
	.multi-banners-section{
		padding-left: 9px;
		padding-right: 9px;
		margin-bottom: 20px;

		.swiper {
			.swiper-button-prev, .swiper-button-next{
				width: 35px;
				height: 35px;
				color: #ffffff;
				border-radius: 50%;
				background-color: $cprimary;
				opacity: 0;
				@include transition(250ms);

				&:after{
					font-size: 15px;
					font-weight: 700;
				}

				&:hover{
					background-color: $cprimary2;
				}
			}

			.swiper-button-prev{
				padding-right: 2px;
				left: 16px;
				@include cp-property(transform, translateX(15px));
			}
			.swiper-button-next{
				padding-left: 2px;
				right: 16px;
				@include cp-property(transform, translateX(-15px));
			}

			&:hover{
				.swiper-button-prev, .swiper-button-next{
					@include cp-property(transform, translateX(0));
					opacity: 1;
				}
			}
		}

		.col-banner{
			a{
				display: block;

				img{
					width: 100%;
				}
			}
		}
	}
	// == ==

	// == Simple banner ==
	.simple-banner-section {
		position: relative;
		margin-bottom: 5px;
		box-shadow: 0 0 9px rgba(0,0,0,0.15);
		z-index: 5;

		a {
			display: block;
			background-color: #efefef;
			min-height: 100px;

			img {
				width: 100%;
			}
		}

		.img-desktop {}
		.img-mobile {
			display: none;
		}

		@media screen and (max-width: 991px) {
			.img-desktop {
				display: none;
			}

			.img-mobile {
				display: block;
			}
		}
	}
	// == ==

	// == Products ==
	.products-section{
		padding-top: 15px;

		.col-sm-title {
			margin-bottom: 5px;
			text-align: center;

			p {
				font-size: 1.1rem;
				font-weight: 500;
				line-height: 1.3;
			}
		}

		.col-products{
			.carousel-t1{
				.swiper-button-prev, .swiper-button-next{
					top: 32%;
					color: $csecondary;
				}

				.swiper-button-disabled{
					display: none !important;
				}
			}
		}
	}
	// == ==

	// == Promotions ==
	.promotions-section {
		position: relative;
		padding-top: 15px;
  	padding-bottom: 28px;
		box-shadow: 0 0 8px rgba(0,0,0,0.2);
		background-color: #f1f2f2;

		.swiper {
			.swiper-slide {
				padding: 5px;
			}

			.swiper-pagination {
				.swiper-pagination-bullet {
					background-color: #ffffff;
				}
			}

			.swiper-button-prev, .swiper-button-next {
				color: $csecondary;

				&:after {
					font-size: 2.1rem;
					font-weight: 700;
				}
			}
			.swiper-button-prev {
				left: 18px;
			}
			.swiper-button-next {
				right: 18px;
			}
			.swiper-button-disabled {
				pointer-events: all;
			}
		}

		.box-promo {
			position: relative;
			display: block;
			cursor: pointer;
			border: 1px solid #a6a6a6;
			border-radius: 4px;
			box-shadow: 0 0 3px rgba(0,0,0,0.3);
			outline: 1px solid transparent;
			overflow: hidden;
			background-color: #ffffff;
			@include transition(250ms);

			img {
				width: 100%;
			}

			&:hover {
				outline: 1px solid #a6a6a6;
			}
		}
	}
	// == ==

	// == Customers ==
	.customers-section {
		position: relative;
		padding: 26px 0;
		background-color: #f0f1f2;

		>.container {
			>.row {
				align-items: center;
			}
		}

		.col-sm-title {
			margin-bottom: 10px;
		}

		.col-logo {
			text-align: center;

			img {
				max-width: 100%;
				max-height: 140px;
			}
		}
	}
	// == ==

}
