#footer{
  position: relative;
  padding: 30px 0;
  width: 100%;
  color: #ffffff;
  background-color: #262528;

  a{
    color: #ffffff;
  }

  // == row top ==
  .row-top{
    .col-newsletter{
      form{
        display: flex;
        padding: 4px;
        border-radius: 0.6rem;
        background-color: #3e3d42;
        @include placeholder(#ffffff, 400, normal, 0.95rem);

        .form-control{
          padding-left: 48px;
          width: calc(100% - 120px);
          height: 38px;
          color: #ffffff;
          font-size: 0.95rem;
          border: 0 !important;
          border-radius: 0.40rem;
          background-color: transparent !important;
          background-image: url('../images/shared/ic-letter.svg');
          background-repeat: no-repeat;
          background-size: 22px 15px;
          background-position: left 13px center;
        }

        .btn-submit{
          width: 130px;
          height: 38px;
          color: #ffffff;
          font-size: 0.95rem;
          font-weight: 600;
          border: 0 !important;
          border-radius: 0.40rem;
          background-color: $csecondary;

          &:hover{
            background-color: $csecondary2;
          }
        }
      }
    }

    .col-middle{
      h6{
        font-size: 1.0rem;
        font-weight: 600;
      }
      h4{
        font-size: 1.35rem;
        font-weight: 400;
      }
    }

    .col-right{
      .box-networks{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: 0 0 15px auto;
        width: 190px;
      }

      .btn-network{
        @include flex-center-xy();
        margin: 0 6px;
        flex: 0 0 35px;
        max-width: 35px;
        height: 35px;
        color: #ffffff;
        font-size: 1.25rem;
        text-decoration: none !important;
        border-radius: 50%;
        background-color: #757676;

        &:hover{
          opacity: 0.85;
        }

        // &.ig{
        //   background: radial-gradient(circle at 30% 107%,#fdf497 0,#fdf497 5%,#fd5949 45%,#d6249f 60%,#285aeb 90%);
        // }
        // &.tw{
        //   background-color: #17a5d3;
        // }
        // &.fb{
        //   background-color: #0a73f7;
        // }
        // &.lk{
        //   background-color: #007dbd;
        // }
      }

      .logo{
        position: relative;
        display: inline-block;
        margin-left: 12px;
        top: -7px;
        margin-left: auto;

        img{
          max-width: 100%;
          max-height: 90px;
        }
      }
    }

    @media screen and (max-width: 1393px){
      .col-right{
        .logo{
          top: 0;
        }
      }
    }
    @media screen and (max-width: 1099px){
      .col-right{
        .logo{
          top: 0;

          img {
            max-height: 43px;
          }
        }
      }
    }
    @media screen and (max-width: 991px){
      .col-middle{
        margin-top: 12px;
        margin-bottom: 12px;
        text-align: center;
      }

      .col-right{
        justify-content: center !important;
        text-align: center;

        .box-networks{
          width: 100%;
          justify-content: center !important;
        }

        .logo{
          margin-top: 15px;
          margin-left: 0;

          img {
            max-height: 120px;
          }
        }
      }
    }
  }
  // == ==

  // == row bottom ==
  .row-bottom{
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 0.95rem;

    .col-menus{
      .title{
        margin-bottom: 6px;
        color: #9c9c9c;
      }

      .col-menu{}
    }

    .col-middle{
      color: #5a5c63;
      font-size: 0.90rem;

      a{
        color: #5a5c63;
      }
    }

    .col-info{
      color: #9c9c9c;
      text-align: right;

      a{
        color: #9c9c9c;
      }

      .icon{
        position: relative;
        margin-left: 6px;
        top: 3px;
        display: inline-block;
        font-size: 1.25rem;
        line-height: 1;
      }

      .box-cert{
        display: inline-block;
        text-align: center;
        font-size: 1rem;

        .safe{
          display: inline-block;
          padding: 5px 16px;
          margin-top: 4px;
          color: #ffffff;
          font-size: 1.15rem;
          font-weight: 700;
          border-radius: 0.30rem;
          background-color: $csecondary;
        }
      }
    }

    @media screen and (max-width: 575px){
      padding-top: 20px;
      padding-bottom: 0;

      .col-menus{
        .col-menu{
          margin-bottom: 11px;
        }
      }

      .col-middle, .col-info{
        text-align: center;
      }
      .col-middle{
        order: 3;
        margin-top: 7px;
      }
    }
  }
  // == ==

}
