.b-toaster-custom-1{
  .b-toaster-slot{
    .b-toast{
      .toast{
        border-color: $cprimary2 !important;

        .toast-header{
          color: #ffffff;
          background-color: $cprimary2;

          .close{
            color: #ffffff;
            text-shadow: none;
            opacity: 1;
          }
        }

        .toast-body{
          color: $cprimary2;
          background-color: #ffffff;
        }
      }
    }
  }
}
