.modal-home-promo{

  .modal-content{
    .modal-header{
      position: relative;
      padding: 0;
      border: 0;
      height: 0;
      z-index: 5;

      .close{
        margin: 0;
        padding: 0.15rem 1rem;
        color: #a20505;
        font-size: 2.6rem;
        font-weight: 500;
        opacity: 1;
      }
    }

    .modal-body{
      padding: 0;
      text-align: center;

      img{
        width: 100%;
      }
    }
  }

}
