// == Titles ==
.col-s-title{
  padding-top: 20px;
  padding-bottom: 14px;

  .title{
    color: $cprimary;
    font-size: 1.8rem;
    font-weight: 800;
    text-align: center;
    // text-transform: uppercase;
  }
}

.col-sm-title{
  padding-top: 10px;
  padding-bottom: 10px;

  .title{
    color: $csecondary;
    font-size: 2.00rem;
    font-weight: 700;
    text-align: center;
  }
}
// == ==
