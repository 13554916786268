#about-us-page-v2{

  .main-section {
    position: relative;
    background-color: #f2f3f2;

    .box-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 50vw;
      height: 100%;
      background-position: center right !important;
      background-image: url('../images/pages/about-us/image-1.jpg');
    }

    >.container {
      >.row {
        align-items: center;
        min-height: 81vh;
      }
    }

    .col-image {
      position: relative;
    }

    .col-text {
      color: #525151;
      font-size: 1.1rem;
      line-height: 1.4;

      .title-s1 {
        color: $cprimary;
        font-size: 2.3rem;
        font-weight: 700;
        letter-spacing: 1px;

        strong {
          font-weight: 800;
        }
      }

      .subtitle {
        color: $csecondary;
        font-size: 1.1rem;
        font-style: italic;
        font-weight: 800;
        text-align: center;
      }

      .b-logos {
        display: block;
        margin-top: 25px;

        img {
          max-width: 100%;
          max-height: 100px;
        }
      }
    }

    @media screen and (max-width: 991px) {
      .box-image {
        width: 100vw;
        opacity: 0.1;
      }

      .col-image {
        display: none;
      }
    }
    @media screen and (max-width: 575px) {
      padding-bottom: 20px;

      .col-text {
        font-size: 0.95rem;
        text-align: center;
      }
    }
  }

  .mission-vision-section-comp {
    padding-top: 0;

    &:before {
      content: " ";
      position: absolute;
      top: -80px;
      width: 100%;
      height: 80px;
      background-position: center top;
      background-repeat: no-repeat;
      background-image: url('../images/pages/about-us/line.svg');
    }
  }
}

.mission-vision-section-comp {
  position: relative;
  padding-top: 25px;
  padding-bottom: 35px;
  color: #ffffff;
  background-color: $csecondary;

  .col-title {
    margin-bottom: 20px;
    text-align: center;

    h2 {
      font-size: 2.2rem;
      font-weight: 600;
    }
  }

  .col-service {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;

    h5 {
      margin-bottom: 8px;
      text-align: center;
      font-size: 1.75rem;
      font-weight: 600;
      letter-spacing: 3px;
    }

    hr {
      border-top-color: rgba(255,255,255,0.15);
    }

    p {
      font-size: 1.00rem;
      text-align: justify;
    }
  }
}

.brands-section-ab{
  .col-brands{
    .swiper{
      .swiper-slide{
        .box-brand{
          @extend .flex-center-xy;
          flex-wrap: wrap;
          flex: 0 0 100%;
          max-width: 100%;
          height: 105px;

          .inside{
            display: inline-block;
            width: 100%;
            text-align: center;

            img{
              max-width: 90%;
              max-height: 90px;
            }
          }
        }
      }
    }
  }
}
