#job-board-page{
  padding-top: 5px;
  min-height: 70vh;

  .content-section{
    padding-top: 35px;
    padding-bottom: 35px;

    >.row{
      min-height: 49vh !important;
    }

    .col-info{
      color: #464646;
      font-size: 1.00rem;
      line-height: 1.4;

      .page-title{
        color: $csecondary;
        font-size: 1.80rem;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
      }

      form{
        position: relative;
        display: inline-block;
        margin-top: 25px;
        width: 100%;
        max-width: 100%;
      }

      .form-group{
        .form-control, .custom-select{
          padding-left: 1.1rem;
          padding-right: 1.1rem;
          border: 1px solid $csecondary;
          border-radius: 6px;
        }

        .custom-file-label {
          &:after {
            content: "Buscar";
            color: #ffffff;
            background-color: $csecondary;
          }
        }
      }

      .btn-send{
        padding: 0.60rem 0.75rem;
        width: 260px;
        max-width: 100%;
        background-color: $csecondary;
        border-color: $csecondary;
        font-size: 1.05rem;
        font-weight: 500;

        &:hover{
          background-color: $csecondary2;
          border-color: $csecondary2;
        }
      }
    }
  }

  .map-section{
    margin-top: 15px;

    iframe{
      display: block;
      width: 100%;
      height: 300px;
      border: 0;
      border-top: 2px solid rgba(0,0,0,0.2);
    }
  }
}
