#header{
	position: relative;

	.header-content{
		position: relative;
		width: 100%;
		background-color: #272629;

		// == Header menu ==
		.header-menu{
			position: relative;

			.navbar{
				background-color: #ffffff !important;

				.navbar-brand{
					padding-top: 0;
					padding-bottom: 0;

					img{
						max-height: 75px;
					}
				}

				.navbar-toggler{
					// padding: 0.25rem 0.65rem;
					// border-color: rgba(255, 255, 255, 0.4);
					border: 0;

					.navbar-toggler-icon{
						display: none;
					}
					.icon{
						color: $cprimary;
						font-size: 1.8rem;
					}
				}

				#nav-collapse{
					.navbar-nav{
						.nav-item{
							.nav-link{
								@include transition(150ms);
								cursor: pointer;

								.submenu-icon{
									position: relative;
									margin-left: 3px;
									top: 0;
									font-size: 75%;
								}
							}
						}

						.item-line{
							.nav-link{
								hr{
									border-top: 1px solid rgba(255,255,255, 0.2);
								}
							}
						}

						.item-simple{
							.nav-link{
								position: relative;
								color: $cprimary;
								font-size: 0.95rem;
								font-weight: 600;

								&:hover{
									color: $cprimary2;
								}
							}
						}

						.item-icons {
							.nav-link {
								position: relative;
								top: -1px;
								line-height: 1.0;

								.btn-network {
									color: $csecondary;
									font-size: 1.6rem;

									&:hover {
										color: $csecondary2;
									}
								}
							}
						}

						.item-sm{
							.nav-link{
								color: $cprimary;
								font-size: 0.95rem;
								font-weight: 500;

								&:hover{
									color: $cprimary2;
								}
							}
						}
					}
				}
			}

			@media screen and (min-width: 992px){
				.navbar{
					padding-bottom: 14px;

					#nav-collapse{
						.navbar-nav{
							.item-simple{
								position: relative;
								// top: 12px;

								.nav-link{
									position: relative;
									padding-left: 0.9rem;
									padding-right: 0.9rem;

									&:after{
										content: " ";
										position: absolute;
										top: 50%;
										left: 0;
										margin-top: -8px;
										height: 16px;
										border-right: 1px solid #38373c;
									}
								}

								&:first-child{
									.nav-link{
										&:after{
											display: none !important;
										}
									}
								}
							}

							.item-sm{
								position: relative;
								// top: 12px;
							}
						}
					}
				}
			}
			@media screen and (min-width: 1300px){
				.navbar{
					#nav-collapse{
						.navbar-nav{
							.item-simple{
								position: relative;
								// top: 12px;

								.nav-link{
									position: relative;
									padding-left: 1.4rem;
									padding-right: 1.4rem;
									font-weight: 600;
									text-transform: uppercase;
								}
							}
						}
					}
				}
			}
			@media screen and (max-width: 991px){
				.navbar{
					.navbar-brand{
						padding-top: 2px;
						padding-bottom: 3px;

						img{
							max-height: 42px;
						}
					}

					#nav-collapse{
						padding-top: 4px;
						padding-bottom: 4px;

						.navbar-nav{
							.nav-item{
								.nav-link{
									padding-top: 0.4rem;
									padding-bottom: 0.4rem;
								}
							}

							.item-sm{
								.nav-link{
									font-size: 0.95rem;
								}
							}
						}
					}
				}
			}
		}
		// == ==

		// == Header options ==
		.header-options{
			position: relative;

			.navbar{
				padding-top: 0.7rem;
				padding-bottom: 0.7rem;
				background-color: #343338 !important;

				#nav-collapse{
					.navbar-nav{
						.search-form{
							width: 42vw;

							form{
								position: relative;
								width: 100%;
								border-radius: 0.6rem;
								box-shadow: 0 0 5px rgba(0,0,0,0.15);
								background-color: #3e3d42;
								@include placeholder(#ffffff, 400, normal, 0.875rem);

								.form-control{
									margin-right: 0 !important;
									padding-left: 49px;
									width: calc(100% - 192px - 130px);
									height: 38px;
									color: #ffffff;
									border: 0 !important;
									border-radius: 0.6rem 0 0 0.6rem;
									background-color: #3e3d42;
									background-image: url('../images/shared/ic-search.svg');
									background-repeat: no-repeat;
									background-size: 21px 21px;
									background-position: left 15px center;
								}

								.custom-select{
									padding-top: 7px;
									padding-left: 0;
									margin-right: 4px;
									width: 188px;
									height: 38px;
									color: #ffffff;
									font-size: 0.95rem;
									border: 0 !important;
									border-radius: 0;
									background-color: #3e3d42;
									// background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23999694' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
									background-image: url('../images/shared/ic-chevron-down.svg');
									background-size: 10px 7px;

									option {
										&:first-child {
											color: #999694;
										}
									}
								}

								.btn-search{
									margin-left: auto;
									width: 130px;
									height: 38px;
									color: #ffffff;
									font-size: 0.90rem;
									font-weight: 600;
									border: 0 !important;
									border-radius: 0.6rem;
									background-color: $csecondary;

									&:hover{
										background-color: $csecondary2;
									}
								}
							}
						}

						.icon-item{
							margin-left: 8px;
							margin-right: 8px;

							.nav-link{
								position: relative;
								top: 3px;
								padding-top: 0.2rem;
								padding-bottom: 0.2rem;

								img{
									max-height: 27px;
								}
							}

							&.cart{
								.nav-link{
									div{
										position: relative;

										u{
											position: absolute;
											text-decoration: none !important;
											top: -6px;
											left: 19px;
											width: 20px;
											height: 20px;
											color: #ffffff;
											font-size: 0.8rem;
											text-align: center;
											border-radius: 50%;
											background-color: $csecondary;
										}
									}
								}
							}
						}

						.item-simple{
							margin-left: 0;
							margin-right: 0;

							.nav-link{
								color: #ffffff;
								font-size: 0.90rem;
								font-weight: 600;

								&:hover{
									color: #d9d5d5;
								}
							}

							&.my-account{
								.nav-link{
									@include transition(150ms);
									display: flex;
									flex-wrap: wrap;
									padding-top: 0.2rem;
									padding-bottom: 0.2rem;
									line-height: 1.15;
									font-weight: 400;
									text-align: right;

									&:hover{
										opacity: 0.85 !important;
										color: #ffffff !important;
									}

									>div{
										display: inline-block;
									}

									.ic-profile{
										position: relative;
										@include flex-center-xy;
										margin-left: 7px;
										width: 33px;
										height: 33px;
										border-radius: 50%;
										background: #fff;

										&:after{
											content: "\f007";
											color: #343338;
											font-family: "Font Awesome 5 Pro";
											font-size: 19px;
											font-weight: 700;
										}
									}
								}
							}
						}

						.brand-select {
							width: auto;
							max-width: 258px;
							height: 38px;
							color: #ffffff;
							border: 0 !important;
							border-radius: 0.6rem;
							background-color: #3e3d42;
							// background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
							background-image: url('../images/shared/ic-chevron-down.svg');
							background-size: 10px 7px;
						}
					}
				}
			}

			@media screen and (min-width: 992px) and (max-width: 1199px){
				.navbar{
					#nav-collapse{
						.navbar-nav{
							.search-form{
								width: 45vw;
							}
						}
					}
				}
			}
			@media screen and (min-width: 1200px) and (max-width: 1400px){
				.navbar{
					#nav-collapse{
						.navbar-nav{
							.search-form{
								width: 54vw;
							}
						}
					}
				}
			}
			@media screen and (max-width: 991px){
				.navbar{
					#nav-collapse{
						flex-wrap: wrap;

						.navbar-nav{
							width: 100%;
							flex: 0 0 100%;

							.search-form{
								width: 100%;
							}

							.brand-select {
								width: calc(100% - 65px);
								max-width: calc(100% - 65px);
							}
						}

						.navbar-nav-options{
							width: 100%;
							flex: 0 0 100%;
						}
					}
				}
			}
			@media screen and (max-width: 575px){
				.navbar{
					#nav-collapse{
						.navbar-nav{
							.search-form{
								form{
									@include placeholder(#ffffff, 400, normal, 0.85rem);

									.form-control{
										padding-left: 15px;
										width: calc(100% - 124px - 62px);
										font-size: 0.85rem;
										background-image: none !important;
									}

									.custom-select{
										width: 120px;
										font-size: 0.85rem;
									}

									.btn-search{
										width: 62px;

										.fa-search{
											font-size: 1.0rem;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		// == ==

	}

	// == Products menu ==
	.products-menu{
		position: fixed;
		padding-top: 15px;
		padding-bottom: 15px;
		top: 0;
		left: 0;
		width: 335px;
		height: 100%;
		color: #fff;
		font-size: 15px;
		box-shadow: 1px 0px 2px rgba(0,0,0,0.2);
		background: $csecondary2;
		opacity: 0;
		visibility: hidden;
		z-index: 480;
		@include cp-property(transform, translateX(-380px) !important);

		a{
			color: #fff;
		}

		.menu-bg{
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100%;
			background-color: transparent;
			z-index: 1;
		}

		.tablist{
			position: relative;
			width: 100%;
			height: 100%;
			overflow: auto;
			overflow-x: hidden;
			z-index: 2;

			.box-title{
				padding: 8px 0px;
				margin-bottom: 6px;
				font-weight: 400;
				text-align: center;
				cursor: pointer;

				&:hover{
					opacity: 0.8;
				}

				h4{
					font-size: 1.2rem;
				}

				.icon{
					position: absolute;
					top: 13px;
					right: 24px;
					font-size: 1.2rem;
				}
			}
		}

		.card{
			border: 0;
			border-radius: 0;
			background-color: transparent;

			.card-header{
				background-color: transparent;
				border: 0;
				border-radius: 0;
				padding: 0.2rem;

				.btn-toggle{
					position: relative;
					display: block;
					padding: 0px 45px 0px 10px;
					text-align: right;
					color: #fff;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					cursor: pointer;
					@extend .t-250;

					.fake-toggle{
						position: absolute;
						right: 0;
						top: 0;
						width: 43px;
						height: 100%;
						z-index: 10;
					}

					&:after{
						content: " ";
						position: absolute;
						top: 7px;
						right: 18px;
						width: 16px;
						height: 8px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						background-image: url('../images/shared/chevron-down.svg');
						z-index: 1;
					}

					&:hover{
						opacity: 0.8;
					}
				}
			}

			.card-body{
				padding: 0.4rem 0;
				line-height: 1.3;
				background-color: #7d0909;

				.ul-sub-options{
					padding-left: 0;
					margin-bottom: 0;
					list-style-type: none;

					li{
						margin-top: 5px;
						margin-bottom: 5px;
						display: inline-block;
						width: 100%;
						font-size: 14px;
						text-align: right;

						a{
							display: block;
							padding: 0px 1.45rem;

							&:hover{
								text-decoration: none;
								opacity: 0.8;
							}
						}
					}
				}
			}
		}

		&.active{
			opacity: 1;
			visibility: visible;
			@include cp-property(transform, translateX(0px) !important);
		}
	}
	// == ==
}
